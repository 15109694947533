<template>
	<div class="menu-intro">
		<div class="menu-intro__text">
			<h3>{{ menuKr }}</h3>
			<h1>{{ menuEn }}</h1>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		menuKr: {
			type: String,
			default: '',
		},
		menuEn: {
			type: String,
			default: '',
		},
	},
};
</script>

<style></style>
